<template>
    <LayoutWithNav
        ><BackOnTop :url="url" />
        <h2>美容集點兌換優惠券</h2>
        <div class="info_wrappper">
            <Tab
                :tabList="tabList"
                :currentTab="currentTab"
                @update="tabHandler"
            />
            <div class="unget_list list" v-show="currentTab === 'unget'">
                <p v-if="!couponUngetList.length">
                    感謝您的支持，目前點數尚不足兌換優惠券，請您繼續累積點數。
                </p>
                <PointCouponItem
                    v-for="(el, idx) in couponUngetList"
                    :key="idx"
                    :data="el"
                    status="unget"
                ></PointCouponItem>
            </div>
            <div class="undone_list list" v-show="currentTab === 'other'">
                <PointCouponItem
                    v-for="(el, idx) in couponUndoneList"
                    :key="idx"
                    :data="el"
                    status="other"
                    :valid="el.valid"
                ></PointCouponItem>
            </div>
        </div>
    </LayoutWithNav>
</template>

<script>
import BackOnTop from '@/components/BackOnTop.vue';
import LayoutWithNav from '@/layouts/LayoutWithNav.vue';
import Tab from '@/components/Tab.vue';
import PointCouponItem from '@/components/PointCouponItem.vue';
import { queryAllRewardCoupon } from '@/apis/api.js';
export default {
    name: 'PointCouponIndex',
    components: { BackOnTop, LayoutWithNav, Tab, PointCouponItem },
    data: () => ({
        tabList: [
            {
                name: '可兌換',
                value: 'unget',
            },
            {
                name: '其他優惠券',
                value: 'other',
            },
        ],
        currentTab: 'unget',
        couponUngetList: [],
        couponUndoneList: [],
        couponDoneList: [],
        url: '/point',
    }),
    beforeCreate() {
        this.$store.state.isLoading = true;
    },
    created() {
        if (
            !localStorage.getItem('phone') ||
            !localStorage.getItem('password') ||
            !localStorage.getItem('member_no')
        ) {
            this.$router.push('/login');
        } else {
            this.getAllRewardCoupon().then(function () {});
        }
    },
    mounted() {
        this.$nextTick(() => {
            this.$store.state.isLoading = false;
        });
    },
    methods: {
        tabHandler(value) {
            this.currentTab = value;
        },
        getAllRewardCoupon() {
            const parent = this;
            return new Promise(function (resolve) {
                queryAllRewardCoupon(
                    localStorage.getItem('phone'),
                    localStorage.getItem('password')
                ).then(function (rsp) {
                    // rsp = JSON.parse(rsp);
                    parent.couponUndoneList = [];
                    parent.couponUngetList = [];
                    for (let i in rsp) {
                        console.log(rsp[i].point, parent.$route.query.point);
                        if (
                            parseInt(rsp[i].point) >
                            parseInt(parent.$route.query.point)
                        ) {
                            parent.couponUndoneList.push(rsp[i]);
                        } else {
                            parent.couponUngetList.push(rsp[i]);
                        }
                    }

                    resolve();
                });
            });
        },
    },
};
</script>
<style lang="scss" scoped>
h2 {
    color: $color_main_first;
    font-size: 26px;
    font-weight: bold;
    padding: 12px 16px;
    line-height: 1;
    text-align: left;
}
.list {
    margin-top: 12px;
    padding: 0 16px 70px;
}
</style>
