<template>
    <div class="p_item d_flex">
        <div
            class="img_wrapper row"
            style="padding-top: 15px; padding-bottom: 10px"
        >
            <div class="col-4">
                <img style="width: 60%" src="../assets/images/logo.png" />
            </div>
            <div
                class="col-5"
                style="
                    font-size: 20px;
                    font-weight: bold;
                    border-right: white solid 1px;
                    margin-top: 10px;
                    margin-bottom: 10px;
                "
            >
                {{ data.sub }}
            </div>
            <div
                class="col-3"
                style="font-size: 16px; margin-top: 15px; margin-bottom: 15px"
            >
                兌換<br />
                <label style="font-weight: bold !important; font-size: 20px">{{
                    data.point
                }}</label
                >點
            </div>
        </div>
        <div
            @click="status == 'unget' ? toggleConfirmPopup() : ''"
            class="detail_wrapper d_flex"
            :class="{ done: false, invalid: false }"
        >
            <h5 :class="status == 'unget' ? 'due' : ''">{{ data.title }}</h5>
            <h6>兌換方式依照集點活動辦法使用</h6>
        </div>
        <PopupPointCouponExchange
            v-model="showPopup"
            :togglePopup="togglePopup"
            :sub="data.sub"
        />
        <PopupConfirmPointCouponExchange
            v-model="showConfirmPopup"
            :card_id="$route.query.id"
            :tNo="data.tNo"
            :toggleConfirmPopup="toggleConfirmPopup"
            :exchangeRewardCoupon="exchangeRewardCouponFunc"
            :point="data.point"
            :sub="data.sub"
        />
    </div>
</template>

<script>
import { exchangeRewardCoupon, getCouponImage } from '@/apis/api.js';
import PopupPointCouponExchange from './Popup/PopupPointCouponExchange.vue';
import PopupConfirmPointCouponExchange from './Popup/PopupConfirmPointCouponExchange.vue';

export default {
    components: { PopupPointCouponExchange, PopupConfirmPointCouponExchange },
    name: 'CouponItem',
    props: {
        data: {
            type: Object,
        },
        status: {
            type: String,
        },
        valid: {
            type: String,
        },
    },
    data: () => ({
        imgSrc: '',
        showConfirmPopup: false,
        showPopup: false,
    }),
    created() {
        console.log('getCouponImage');
        var parent = this;
        getCouponImage(this.data.tNo).then(function (image_url) {
            parent.imgSrc = image_url;
        });
    },
    mounted() {},
    methods: {
        toggleConfirmPopup() {
            this.showConfirmPopup = !this.showConfirmPopup;
        },
        togglePopup() {
            this.showPopup = !this.showPopup;
            if (!this.showPopup) {
                this.$router.push('/info/coupon?type=use');
            }
        },
        exchangeRewardCouponFunc(card_id, tNo) {
            const parent = this;
            exchangeRewardCoupon(
                localStorage.getItem('phone'),
                localStorage.getItem('password'),
                card_id,
                tNo
            ).then(function (rsp) {
                if (rsp.match('Success') != null) {
                    parent.togglePopup();
                }
            });
        },
    },
    watch: {
        data() {
            var parent = this;
            console.log('couponitem', this.data);
            getCouponImage(this.data.tNo).then(function (image_url) {
                parent.imgSrc = image_url;
            });
        },
    },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.p_item {
    flex-direction: column;
    background: #f8f1ec;
    box-shadow: 0px 4px 7px rgba(0, 0, 0, 0.15);
    border-radius: 16px;
    margin-bottom: 16px;
    overflow: hidden;
    transition: background 0.3s;

    .img_wrapper {
        width: 100%;
        background: $color_main_first;
        color: white;
    }
    .detail_wrapper {
        width: 100%;
        text-align: left;
        padding: 16px;
        flex-direction: column;

        h5 {
            color: $color_main_first;
            font-weight: bold;
            font-size: 20px;
            margin-bottom: 4px;
        }
        h6 {
            color: $color_main_grey_dark;
            font-size: 16px;
            margin-bottom: 4px;
        }
        .due {
            background-image: url('~@/assets/images/ic24ArrowR.svg');
            background-repeat: no-repeat;
            background-position: right center;
            background-size: 8px;
        }
        &.done {
            background-image: url('~@/assets/images/ic_ticket.svg');
            background-repeat: no-repeat;
            background-position: right 15px top 32%;
            .due {
                background: none;
            }
        }
        &.invalid {
            background-image: url('~@/assets/images/ic_ticket_expired.svg');
            background-repeat: no-repeat;
            background-position: right 15px top 32%;
        }
    }

    * {
        font-size: 14px;
        line-height: 1.4;
    }

    &:active {
        background: #e9e0da;
    }
}
</style>
